@import 'normalize';
@import url('//use.fontawesome.com/releases/v4.7.0/css/font-awesome-css.min.css');@font-face{font-family:'FontAwesome';src:url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.eot');font-display:swap;src:url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
  url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.woff2') format('woff2'),
  url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.woff') format('woff'),
  url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.ttf') format('truetype'),
  url('//use.fontawesome.com/releases/v4.7.0/fonts/fontawesome-webfont.svg#fontawesomeregular') format('svg');}
  @import url('https://fonts.googleapis.com/css?family=Raleway');
  /*
====================================
Default Styling
====================================
*/
img {
	max-width: 100%;
}
body {
	color: #333;
}

a.link-4 {
  color:#1f253d;
  content: '';
  border-bottom: solid 1px black;
}
a:hover.link-4 {
  color:#1f253d;
  border-bottom: solid thin #5CAAEF;
}
a.link-4  {
  -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
}

  .iconsHome{
    font-size: 1.2em;
  padding-right:.5em;
  }

//footer
footer{
  background: #424242;
  padding:2%;
}
ul.cities li{
  list-style:none;
}
ul.cities a{
  color:#fff;
  font-size:.8em;
}
ul.cities a:hover{
  color:rgba(250,250,250,.7);
}
footer h3{
  text-transform: uppercase;
  text-align:center;
  color:#fff;
}
.socialsNetwork{

  color:#fff;
  width:100%;
background: #616161;
}
.socialsNetwork p{
  font-size: .8em;
}
.socialsNetwork a{
  font-size: .8em;
  color:#fff;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
}
/*new nav*/



.toggle, [id^=drop] {
  display: none;
 }
 
 nav {
   margin: 0;
   padding: 0;
   background-color: #444;
   z-index: 999;
 }
 
 #logo {
   display: block;
   padding: 0 30px;
   float: left;
   font-size: 20px;
   line-height: 60px;
 }
 
 nav:after {
   content: "";
   display: table;
   clear: both;
 }
   
   
     nav ul {
       padding: 0;
       margin: 0;
       list-style: none;
       position: relative;
     }
     
     nav ul li {
       margin: 0px;
       display: inline-block;
       float: left;
       background-color: #444;
       color:#fff;
     }
     body > div:nth-child(1) > div > nav > ul > li:nth-child(1) > a > i{
      color:#FFF;
    }
   
     nav a {
       display: block;
       padding: 0 20px;
       color: #FFF;
       font-size: .9em;
       font-weight:300;
       line-height: 60px;
       text-decoration: none;
     }
     
     nav ul li ul li:hover { background: #000000; }
     
     nav a:hover { background-color: #000000; }
     
     nav ul ul {
       display: none;
       position: absolute;
       top: 60px;
       z-index:999;
     }
     
     nav ul li:hover > ul { display: inherit; }
     
     nav ul ul li {
       width: 250px;
       float: none;
       display: list-item;
       position: relative;
     }
     
     nav ul ul ul li {
       position: relative;
       top: -60px;
       left: 170px;
     }
     
    nav ul li > a:after { content: ' +'; }
     
     li > a:only-child:after { content: ''; }
     
     
     /* Media Queries
     --------------------------------------------- */
     
     @media all and (max-width : 1200px) {
     
     
     nav { margin: 0; }
     
     .toggle + a,
      .menu { display: none; 
        color: #FFF;}
     
     .toggle {
       display: block;
       background-color: #444;
       padding: 0 20px;
       color: #FFF;
       font-size: 20px;
       line-height: 60px;
       text-decoration: none;
       border: none;
     }
     
     .toggle:hover { background-color: #000000; }
     
     [id^=drop]:checked + ul { display: block; }
     
     nav ul li {
       display: block;
       width: 100%;
     }
     
     nav ul ul .toggle,
      nav ul ul a { padding: 0 40px; }
     
     nav ul ul ul a { padding: 0 80px; }
     
     nav a:hover,
      nav ul ul ul a { background-color: #000000; }
     
     nav ul li ul li .toggle,
      nav ul ul a { background-color: #212121; }
     
     nav ul ul {
       float: none;
       position: static;
       color: #ffffff;
     }
     body > nav > ul > li:nth-child(1) > a > i{
  color:#fff;
}
i.fa.fa-home{
  color:#fff;
}
     nav ul ul li:hover > ul,
     nav ul li:hover > ul { display: none; }
     
     nav ul ul li {
       display: block;
       width: 100%;
     }
     
     nav ul ul ul li { position: static;
     
     }
     }
     
     @media all and (max-width : 330px) {
     
     nav ul li {
       display: block;
       width: 94%;
     }
     
     }
    /*Breadcrumbs*/
    .breadcrumbs {
      border-bottom: 1px solid #ddd;
      background-color: #f5f5f5;
    }
    .breadcrumbs ul {
        list-style:none; 
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
    .breadcrumbs li {
      float: left;
      width: 20%;
    }
    .breadcrumbs a {
      position: relative;
      display: block;
      padding: 20px;
      padding-right: 0 !important; 
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      color: #aaa;
      cursor: pointer;
    }
    .breadcrumbs a:hover {
      background: #eee;
    }
    .breadcrumbs a.active {
      color: #777;
      background-color: #fafafa;
    }
    .breadcrumbs a.active span:first-child {
      color: #fff;
      border-color: #777;
      background-color: #777;
    }
    .breadcrumbs a:before {
      margin-left: 1px;
      border-left-color: #d5d5d5;
    }
    .breadcrumbs a:after {
      border-left-color: #f5f5f5;
    }
    .breadcrumbs a:hover:after {
      border-left-color: #eee;
    }
    .breadcrumbs a.active:after {
      border-left-color: #fafafa;
    }
    .breadcrumbs li:last-child a:before,
    .breadcrumbs li:last-child a:after {
      display: none;
    }
    @media (max-width: 720px) { 
      .breadcrumbs a {
        padding: 15px;
      }
      .breadcrumbs a:before,
      .breadcrumbs a:after {
        border-top-width: 26px;
        border-bottom-width: 26px;
        border-left-width: 13px;
      }
    }
    @media (max-width: 620px) { 
      .breadcrumbs a {
        padding: 10px;
        font-size: 12px;
      }
      .breadcrumbs a:before,
      .breadcrumbs a:after {
        border-top-width: 22px;
        border-bottom-width: 22px;
        border-left-width: 11px;
      }
    }
    @media (max-width: 520px) {
      .breadcrumbs a {
        padding: 5px;
      }
      .breadcrumbs a:before,
      .breadcrumbs a:after {
        border-top-width: 16px;
        border-bottom-width: 16px;
        border-left-width: 8px;
      }
      .breadcrumbs li a span:first-child {
        display: block;
        margin: 0 auto;
      }
      .breadcrumbs li a span:last-child {
        display: none;
      }
    }
    .breadcrumbs a span:first-child {
      display: inline-block;
      width: 22px;
      height: 22px;
      padding: 2px;
      margin-right: 5px;
      border: 2px solid #aaa;
      border-radius: 50%;
      background-color: #fff;
    }
    .breadcrumbs a:before,
    .breadcrumbs a:after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      z-index: 1;
      display: block;
      width: 0;
      height: 0;
      border-top: 32px solid transparent;
      border-bottom: 32px solid transparent;
      border-left: 16px solid transparent;
    }
    .breadcrumbs li {
      float: left;
      width: 20%;
    }
    .breadcrumbs a {
      position: relative;
      display: block;
      padding: 20px;
      padding-right: 0 !important; 
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      color: #aaa;
      cursor: pointer;
    }
    .cta {
      border: 12px solid #000;
      padding: 20px;
      font-weight: 800;
      letter-spacing: 2px;
      font-size: 60px;
      color: #000;
      line-height: 0.8;
      -webkit-box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.4);
      box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.4);
  }
		  /*BROWSER*/
.browser {
  background-color: #F1F1F1;
  max-width: 100%;
  height: auto;
  border-radius: 3px;
  overflow: hidden;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.top-bar--buttons {
  height: 20px;
  background-color: #CCCCCC;
  padding-left: 5px;
}
.top-bar--buttons i {
  width: 8px;
  height: 8px;
  display: block;
  margin: 5px 0 0 5px;
  border-radius: 100%;
  float: left;
}
.top-bar--buttons .red {
  background-color: #fc635e;
  border: 1px solid #dc4441;
}
.top-bar--buttons .yellow {
  background-color: #fdbc40;
  border: 1px solid #db9c31;
}
.top-bar--buttons .green {
  background-color: #35cd4b;
  border: 1px solid #24a732;
}
.top-bar--tabs {
  position: relative;
}
.top-bar--tabs .tab {
  position: absolute;
  height: 15px;
  background: #E6E6E6;
  width: 150px;
  top: -15px;
  left: 80px;
}
.top-bar--tabs .tab::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset 16px;
  position: absolute;
  border-color: transparent #E6E6E6 transparent transparent;
  border-right-style: solid;
  border-left-width: 0;
  left: -16px;
}
.top-bar--tabs .tab::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: inset 16px;
  position: absolute;
  border-color: transparent transparent transparent #E6E6E6;
  border-left-style: solid;
  border-right-width: 0;
  right: -16px;
}
.top-bar--url {
  background-color: #E6E6E6;
  height: 20px;
}
.screenshot img {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.browser:hover {
  bottom: 0px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.2);
}

/*Sub-Menu requirements*/
ul.list { 
  margin: 0 auto; 
  padding: 0; 
  list-style: none; 
  display: table;
  width: 100%;
  text-align: center;
}
ul.list > li { 
  display: table-cell; 
  position: relative; 
  padding: 15px 0;
}
ul.list > li > a {
  color: #e64c65;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em; 
  display: inline-block;
  padding: 15px 20px;
  position: relative;
}
ul.list > li > a:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #e64c65;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
ul.list > li > a:hover:after { 
  width: 100%; 
  left: 0; 
}

.headerTop{
  background: #fafafa;
}
.headerTop h1{
  color:#000;
}
/*FAQ*/
.faq-header {
  font-size: 2em;
  border-bottom: 1px dotted #ccc;
  padding: 1em 0;
}
.faq-c {
  border-bottom: 1px dotted #ccc;
  padding: 1em 0;
}
.faq-t {
  line-height: 1em;
  color: #aaa;
  font-family: sans-serif;
  float: left;
  font-weight: 700;
  padding-right: 0.3em;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  transition: all 200ms;
}
.faq-o {
  transform: rotate(-45deg);
  transform-origin: 50% 50%;
  -ms-transform: rotate(-45deg);
  -ms-transform-origin: 50% 50%;
  -webkit-transform: rotate(-45deg);
  -webkit-transform-origin: 50% 50%;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  transition: all 200ms;
}
.faq-q {
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 100;
}
.faq-a {
  clear: both;
  color: #666;
  display: none;
  padding-left: 1.5em;
}
.fa-quote-left {
  color: #444;
  background: #eee;
  border: 2px solid #ccc;
  padding: 10px 12px;
  border-radius: 100%;
}
/*Table referral Delivery*/
.wrap {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  color: #989A8F;
}
.table {
  background-color: #ffffff;
  min-height: 360px;
  width: 100%;
  margin-top: 50px;
}
.signUp>li {
  float: left;
  width: 250px;
  text-align: center;
  border: 1px solid #DDDCD8;
  list-style: none;
}
.top {
  background-color: #EAE9E4;
  height: 75px;
}
.top h2 {
  padding-top: 20px;
}
.circle {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  font-size: 20px;
  color: #fff;
  line-height: 60px;
  text-align: center;
  background: #989A8F;
  margin-left: 70px;
  margin-top: 10px;
}
.bottom {
  margin-top: 100px;
}
.bottom p {
  font-size: 1em;
  ;
  padding: 5%;
  ;
}
.bottom p span {
  font-weight: bold;
}
.sign .button {
  border: 1px solid #989A8F;
  padding: 10px 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  color: #989A8F;
  font-size: 14px;
  text-decoration: none;
  vertical-align: middle;
  font-size: 17px;
  background-color: #fff;
}
.button.purple {
  background-color: #5D4660;
}
.white {
  color: #FFFFFF;
}
.pink {
  background-color: #BC9B94;
}
/*COLORS*/
.lyft-color{
 color: #EA0B8C;
}
.light-gray {
  background-color: #f6f6f6;
}

/*Sign up Bonus*/
.circle {
  border-radius: 38px;
  height: 76px;
  width: 76px;
  color: aliceblue;
  background-color: #448aff;
}
.ptag {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font: 300 28px/38px "Roboto Slab", palatino, serif;
  letter-spacing: -.2px;
  text-rendering: optimizeLegibility;
  line-height: 1.5em;
  text-align: center;
  margin: 0 0 25px;
}
.button {
  max-width: 100%;
  background: #483f99;
  border: none;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-family: Roboto, arial, sans-serif;
  font-size: 16px;
  height: 48px;
  letter-spacing: .5px;
  line-height: 48px;
  padding: 0 24px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}
.card.card-related .card-inner {
  color: #a3a3a3;
  display: block;
  min-height: 400px;
  padding-bottom: 60px;
  position: relative;
  z-index: 500;
}
.card .card-inner {
  transition: box-shadow .2s ease-in-out;
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  display: block;
  padding: 30px;
  width: 100%;
  min-height: 400px;
}
video{
  max-width:80%;
  margin:0 auto;
      }
      .font-large{
        padding-top:3%;
        font-weight: 300;
      
      }
      .link-more{
        font-weight: 500;
        text-transform:uppercase;
      }

.activity-pill {
  position:relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
min-height: 140px;
margin-top: 10px;
margin-bottom: 10px;
padding: 25px 30px;
-webkit-box-pack: start;
-webkit-justify-content: flex-start;
-ms-flex-pack: start;
justify-content: flex-start;
border-radius: 25px;
background-color: #0c1524;
-webkit-transform-origin: 50% 0%;
-ms-transform-origin: 50% 0%;
transform-origin: 50% 0%;
color: #5b769b;
}

.activity-pill.centered {
margin-top: 0px;
margin-bottom: 0px;
padding: 10px 15px;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
background-image: url('../images/quote.svg');
background-position: 96% 22px;
background-size: 50px;
background-repeat: no-repeat;
-webkit-transform-origin: 50% 0%;
-ms-transform-origin: 50% 0%;
transform-origin: 50% 0%;
}
.activity-pill p{
color: #fff;
}
.activity-sub-heading {
margin-bottom: 5px;
font-family: 'Roboto Condensed', sans-serif;
color: #fff;
font-size: 18px;
font-weight: 700;
}

.pill-description {
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 10px 30px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: start;
-webkit-align-items: flex-start;
-ms-flex-align: start;
align-items: flex-start;
text-align: left;
}

.pill-description.small {
padding-right: 20px;
padding-left: 20px;
font-size: 13px;
}
.new-pill {

position: absolute;
top: 8px;
right: 8px;
z-index: 2;
padding-right: 5px;
padding-left: 5px;
border-radius: 12px;
background-color: #5245C2;
color: #fff;
font-size: 10px;
line-height: 15px;
}

.new-pill.upper-right {
left:5px;
top: 5px;
right: auto;
bottom: auto;
}
.space-button-centered {
position: relative;
 margin-top: 25px;
padding:5%;
background-color: rgba(28, 46, 70, .65);
box-shadow: inset 0 0 0 1px rgba(91, 118, 155, .34);
opacity: 1;
-webkit-transition: opacity 200ms ease, box-shadow 200ms ease;
transition: opacity 200ms ease, box-shadow 200ms ease;
color: #fff;
font-size:.5em;
font-weight: 700;
letter-spacing: 1px;
text-decoration: none;
text-transform: uppercase;
}

.space-button-centered:hover {
border-color: #5b769b;
box-shadow: inset 0 0 0 1px #5b769b;
opacity: 1;
}

#profile-description {
  max-width: 100%; 
  margin-top: 50px; 
  position:relative;
}
#profile-description .text {
/*   width: 660px;  */
  margin-bottom: 5px; 
  color: #000; 
  padding: 0 15px; 
  position:relative; 
  font-family: Arial; 
  font-size: 14px; 
  display: block;
}
#profile-description .show-more {
/*   width: 690px;  */
  color: #000; 
  position:relative; 
  font-size: 12px; 
  padding-top: 5px; 
  height: 20px; 
  text-align: center; 
  background: #f1f1f1; 
  cursor: pointer;
}
#profile-description .show-more:hover { 
    color: #1779dd;
}
#profile-description .show-more-height { 
  height: 65px; 
  overflow:hidden; 
}
.secondary-nav a {
  line-height:2.2em;
  display: block;
  color:#212121;
  width: 100%;
  border-bottom: 1px dotted;
  text-decoration: none;
  margin: .5em 0;
}
.link-3  a {
  text-decoration: none;
  color: #5245C2;
  -moz-transition-property: all;
  -o-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.1s;
  -o-transition-duration: 0.1s;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.link-3 a:hover {
  text-decoration: none;
  color: #5245C2;
}
.link-3 a {

  position: relative;
  display: inline-block;
  color: #000;
}
.link-3 a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15px;
  height: 2px;
  padding-bottom: 1px;
  content: '';
  background: #5245C2;
  -moz-transition-property: all;
  -o-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.link-3 a:hover {
  text-decoration: none;
  color: #000;
}
.link-3 a:hover:after {
  width: 100%}
  /* Position child elements relative to this element */
.aspect-ratio-box {
  position: relative;
}

/* Create a pseudo element that uses padding-bottom to take up space */
.aspect-ratio-box::after {
  display: block;
  content: '';
  /* 16:9 aspect ratio */
  padding-bottom: 56.25%;
}

/* Image is positioned absolutely relative to the parent element */
.aspect-ratio-box img {
  /* Image should match parent box size */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}